import CardGrid from '@components/cardGrid';
import vercelIcon from '@assets/logos/vercel.svg';
import herokuIcon from '@assets/logos/heroku.svg';
import netlifyIcon from '@assets/logos/netlify.svg';
import firebaseIcon from '@assets/logos/firebase.svg';
import githubPagesIcon from '@assets/logos/github.svg';
import gitlabPagesIcon from '@assets/logos/gitlab.svg';
import awsIcon from '@assets/logos/aws.svg';
import azureIcon from '@assets/logos/azure.svg';
import googleCloudIcon from '@assets/logos/google-cloud.svg';
import * as React from 'react';
export default {
  CardGrid,
  vercelIcon,
  herokuIcon,
  netlifyIcon,
  firebaseIcon,
  githubPagesIcon,
  gitlabPagesIcon,
  awsIcon,
  azureIcon,
  googleCloudIcon,
  React
};