import React from 'react';

const Card = ({ data, cssClasses }) => {
  // console.log('data', data);
  return (
    <div className={`card h-full bg-white ${cssClasses}`}>
      <div className="card-body">
        {data.logo && (
          <div className="card-icon">
            <img
              className="h-24"
              src={data.logo}
              alt={data.title}
              title={data.title}
            />
          </div>
        )}
        <p className="text-xl font-bold mb-2">{data.title}</p>
        {/* <p>{data.description}</p> */}
        <div>
          <div className="badge-inline">
            {data.level && (
              <span className="badge badge-pill bg-primary text-white">
                {data.level}
              </span>
            )}
            {data.tags &&
              data.tags.map((tag, index2) => (
                <span key={index2} className="badge badge-pill bg-gray-300">
                  {tag}
                </span>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
