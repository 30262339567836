export const getPagesById = (pages, id, base) => {
  const regex = new RegExp(`^${id}/${base}/`, 'i');
  const result = pages.filter(({ slug }) => slug.match(regex));

  return result.reduce((acc, el) => {
    let slug = el.slug.split(regex)[1];
    slug = slug.endsWith("/") ? slug.slice(0, -1) : slug;
    return {
      ...acc,
      [slug]: {
        title: el.frontmatter.title,
        href: el.slug,
        tableOfContents: el.tableOfContents
      },
    }
  }, {});
}