import CardGrid from '@components/cardGrid';
import mixkitIcon from '@assets/logos/mixkit.svg';
import videvoIcon from '@assets/logos/videvo.svg';
import videezyIcon from '@assets/logos/videezy.svg';
import lifeofvidsIcon from '@assets/logos/lifeofvids.svg';
import mazwaiIcon from '@assets/logos/mazwai.svg';
import pexelsIcon from '@assets/logos/pexels.svg';
import pixabayIcon from '@assets/logos/pixabay.svg';
import splitshireIcon from '@assets/logos/splitshire.svg';
import isorepublicIcon from '@assets/logos/isorepublic.svg';
import * as React from 'react';
export default {
  CardGrid,
  mixkitIcon,
  videvoIcon,
  videezyIcon,
  lifeofvidsIcon,
  mazwaiIcon,
  pexelsIcon,
  pixabayIcon,
  splitshireIcon,
  isorepublicIcon,
  React
};