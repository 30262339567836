import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "recursos"
    }}>{`Recursos`}</h1>
    <p>{`bla bla bla calcular area triangulo`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      