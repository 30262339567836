import React, { useState } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
// import assert from 'assert';
// import { element } from 'prop-types';

const LiveCode = ({ functionName, initCode, testCases }) => {
  const [result, setResult] = useState([]);
  const [code, setCode] = useState(initCode);
  const [codeError, setCodeError] = useState(null);

  // const tests = [
  //   () => assert.strictEqual(2+2, 4),
  //   () => assert.strictEqual(2+3, 4),
  //   () => assert.strictEqual(2+4, 6),
  // ];
  // tests.forEach((test) => {
  //   try {
  //     test();
  //     console.log('TEST OK');
  //   } catch (error) {
  //     console.log('TEST ERROR', error);
  //   }
  // });

  const test = () => {
    try {
      setCodeError(null);
      const codeModify = code + `\nresult = ${functionName}`;

      /* eslint no-eval: 0 */
      const functionTest = eval(codeModify);

      return testCases.reduce((acc, element) => {
        const result = functionTest(...element.parameters);
        acc.push({
          result,
          isOk: element.result === result,
          preview: `${functionName}(${element.parameters.join(', ')}) ➞ ${result}`,
        });
        return acc;
      }, []);
    } catch (err) {
      setCodeError(err);
    }
  };

  return (
    <div
      className={`grid content-center grid-cols-1 sm:grid-cols-3 gap-y-4 sm:gap-x-4`}
    >
      <div className="col-span-2">
      { CodeMirror && <CodeMirror
          value={code}
          options={{
            mode: 'javascript',
            theme: 'material',
            lineWrapping: true,
            smartIndent: true,
            lineNumbers: true,
            foldGutter: true,
            gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
            autoCloseTags: true,
            keyMap: 'sublime',
            matchBrackets: true,
            autoCloseBrackets: true,
            extraKeys: {
              'Ctrl-Space': 'autocomplete',
              Tab: 'autocomplete',
            },
          }}
          onBeforeChange={(editor, data, value) => {
            setCode(value);
          }}
        />}
        <button
          className="btn btn-primary mt-3 w-full"
          onClick={() => setResult(test())}
        >
          Verificar
        </button>
      </div>

      <div className="px-4 bg-gray-200 rounded text-left">
        {codeError ? (
          <div className='text-center text-red-600'>
            <h2>ERROR</h2>
            <p>{codeError.message}</p>
          </div>
        ) : (
          result?.map((element, index) => (
            <p key={index} style={{ color: element.isOk ? 'green' : 'red' }}>
              {element.preview}
            </p>
          ))
        )}
      </div>
    </div>
  );
};

export default LiveCode;
