import React from 'react';
import { Link } from 'gatsby';

// Components
import Card from './card';

const CardGrid = ({ elements, path, cols = 1, cssClasses, location }) => {
  // console.log('elements', elements, path, cssClasses, location);

  const getWidth = (cols) => {
    if (cols === 1) {
      return 'md:w-full';
    }
    return `md:w-1/${cols}`;
  };

  return (
    <div className={`flex flex-wrap justify-center`}>
      {/* <div className={`grid content-center grid-cols-1 sm:grid-cols-${cols}`}> */}
      {elements.map((element, index) =>
        element.link ? (
          <a
            href={element.link}
            target="_blank"
            rel="noreferrer"
            className={`w-full ${getWidth(cols)} p-2`}
            style={{ color: 'currentColor' }}
            key={index}
          >
            <Card data={element} cssClasses={cssClasses} />
          </a>
        ) : (
          <Link
            to={`${path ? '/' + path : ''}${element.path}`}
            // to={`/${path ? path + '/' : ''}${element.id}`}
            className={`w-full ${getWidth(cols)} p-2`}
            key={index}
          >
            <Card data={element} cssClasses={cssClasses} />
          </Link>
        ),
      )}
    </div>
  );
};

export default CardGrid;
