// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-challenge-template-js": () => import("./../../../src/templates/challenge-template.js" /* webpackChunkName: "component---src-templates-challenge-template-js" */),
  "component---src-templates-default-template-js": () => import("./../../../src/templates/default-template.js" /* webpackChunkName: "component---src-templates-default-template-js" */),
  "component---src-templates-doc-template-js": () => import("./../../../src/templates/doc-template.js" /* webpackChunkName: "component---src-templates-doc-template-js" */),
  "component---src-templates-resource-template-js": () => import("./../../../src/templates/resource-template.js" /* webpackChunkName: "component---src-templates-resource-template-js" */)
}

