import * as Constants from '../../constants/content.constant';
import { getPagesById } from '@utils/pages-util';

// Icon
import icon from '@assets/logos/kubernets.svg';

const ID = 'kubernets';

const getMenu = (allPages, base) => {
  const pages = getPagesById(allPages, ID, base);
  return {
    'Introducción': {
      pages: [
        pages[''],
      ],
      isOpen: true,
    },
    'Commandos': {
      pages: [
        pages['commands'],
      ],
      isOpen: true,
    },
  };
}

export const KUBERNETS = {
  id: ID,
  path: '/kubernets',
  title: 'Kubernetes',
  logo: icon,
  options: [
    Constants.OPTION_DOCS,
  ],
  getMenu,
};