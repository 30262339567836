import React from 'react';
import { MDXProvider } from '@mdx-js/react';

//Contexts
import { StoreContextProvider } from './src/contexts/StoreContextProvider';

import Layout from './src/layouts/default-layout';
import './src/styles/style.scss';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/javascript/javascript';

import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/javascript-hint';
import 'codemirror/keymap/sublime';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/closetag';
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/comment-fold';
import 'codemirror/addon/fold/foldgutter.css';

import { HighlightCode } from './src/components/highlightCode'

/* eslint-disable */
const component = {
  pre: (props) => {
    return <HighlightCode {...props} />
  },
};

export const wrapRootElement = ({ element }) => (
  <MDXProvider components={component}>
    <StoreContextProvider>
      <Layout>{element}</Layout>
    </StoreContextProvider>
  </MDXProvider>
);

// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition
// }) => {
//   const currentPosition = getSavedScrollPosition(location)
//   const queriedPosition = getSavedScrollPosition({ pathname: `/random` })

//   window.scrollTo(...(currentPosition || [0, 0]))

//   return false
// }
