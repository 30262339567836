import React from 'react'
import Highlight, { defaultProps } from 'prism-react-renderer'
// import dracula from 'prism-react-renderer/themes/dracula'
import theme from 'prism-react-renderer/themes/nightOwl';
// import '../styles/prism-themes/night-owl.css';

const copyToClipboard = str => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

const Button = props => (
  <button
    style={{
      position: "absolute",
      top: 0,
      right: 0,
      border: "none",
      boxShadow: "none",
      textDecoration: "none",
      margin: "8px",
      padding: "5px 10px",
      background: "#E2E8F022",
      color: "white",
      borderRadius: "8px",
      cursor: "pointer",
      fontSize: "13px",
      fontFamily: "sans-serif",
      lineHeight: "1",
    }}
    {...props}
  />
)

export const HighlightCode = ({ codeString, children, language, ...props }) => {
  const [isCopied, setIsCopied] = React.useState(false);

  const className = children.props.className || '';
    const matches = className.match(/language-(?<lang>.*)/);
    return (
      <Highlight
        {...defaultProps}
        theme={theme}
        code={children.props.children.trim()}
        language={
          matches && matches.groups && matches.groups.lang
            ? matches.groups.lang
            : ''
        }
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre
            className={className}
            style={{
              ...style,
              fontSize: '1.1rem',
              fontStyle: 'normal !important',
              borderRadius: '0.25rem',
              marginTop: '1rem',
              marginBottom: '2rem',
              padding: '1.5rem',
              overflowX: 'auto',
              position: 'relative',
            }}
          >
            <Button
              onClick={() => {
                copyToClipboard(children.props.children.trim())
                setIsCopied(true)
                setTimeout(() => setIsCopied(false), 2000)
              }}
              >
              {isCopied ? "Copied!" : "Copy"}
            </Button>
            
            {tokens.map((line, i) => (
              <div key={i} {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    );
}