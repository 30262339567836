import React from 'react';

const Tab = ({ activeTab, label, onClick }) => {
  return (
    <button
      className={`flex-1 sm:text-center font-medium pb-3 cursor-pointer hover:text-blue-400 focus:outline-none ${
        activeTab === label
          ? 'text-blue-400 border-b-4 border-solid border-blue-400'
          : 'false'
      }`}
      onClick={() => onClick(label)}
    >
      {label}
    </button>
  );
};

export default Tab;
