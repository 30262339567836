import CardGrid from '@components/cardGrid';
import codierIcon from '@assets/logos/codier.svg';
import acefrontendIcon from '@assets/logos/acefrontend.svg';
import frontendmentorIcon from '@assets/logos/frontendmentor.svg';
import * as React from 'react';
export default {
  CardGrid,
  codierIcon,
  acefrontendIcon,
  frontendmentorIcon,
  React
};