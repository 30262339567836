import React from 'react';
import { navigate } from "gatsby"
import PropTypes from 'prop-types';
import CardGrid from './cardGrid';

// Icons
import copaIcon from '../assets/icons/copa.svg';
import documentIcon from '../assets/icons/document.svg';
import examIcon from '../assets/icons/exam.svg';

const MENU_DATA = {
  docs: {
    path: '/docs',
    title: 'Documentación',
    logo: documentIcon,
  },
  quizzes: {
    path: '/quizzes',
    title: 'Examen',
    logo: examIcon,
  },
  challenges: {
    path: '/challenges',
    title: 'Desafíos',
    logo: copaIcon,
  },
};

const ResourceMenuGrid = ({ title, description, path, resources }) => {
  const modules = resources.reduce((acc, el) => {
    acc.push(MENU_DATA[el]);
    return acc;
  }, []);
  // if(modules?.length === 1) {
  //   navigate(`${path ? '/' + path : ''}${modules[0].path}`);
  //   return <div>Cargando</div>
  // }

  return (
    <div
      className="container mx-auto px-4 text-center grid content-center"
      style={{ minHeight: 'calc(100vh - 233px)' }}
    >
      <h1 className="font-bold">{title}</h1>
      <p>{description}</p>
      <CardGrid path={path} elements={modules} cols={3} cssClasses={'text-center'} />
    </div>
  );
};

ResourceMenuGrid.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  path: PropTypes.string,
  resources: PropTypes.array,
};

export default ResourceMenuGrid;
