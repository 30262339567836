import React, { createContext, useState } from 'react';
export const StoreContext = createContext();

export const StoreContextProvider = ({ children }) => {
  const [pages, setPages] = useState({});
  const [menu, setMenu] = useState({
    leo: {
      title: 'leooo',
      description: 'aaaaa'
    },
    dani: {
      title: 'daniii',
      description: 'bbbbb'
    },
  });

  const getMenuDoc = (id) => {
    return menu;
    // return menuDoc[id];
  };

  return (
    <StoreContext.Provider
      value={{
        pages,
        setPages,
        menu,
        setMenu,
        getMenuDoc,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
