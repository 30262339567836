import React, { useState } from 'react';
import { Link } from 'gatsby';
// import Card from './card';
import CardGrid from './cardGrid';

//Gif
import notFound from '@assets/gif/notfound.gif';

const Challenges = ({ data, levels, tags, elements }) => {
  // console.log('data', data, 'levels', levels, 'tags', tags, 'elements', elements);
  const [elementsData, setElementsData] = useState(elements);
  const [level, setLevel] = useState('');
  const [tag, setTag] = useState('');

  // console.log('level', level);
  // console.log('tag', tag);

  const cleaned = () => {
    setTag('');
    setLevel('');
    setElementsData(elements)
  };

  const filter = () => {
    if (level || tag) {
      let elementsFilter = elements;

      if (level) {
        elementsFilter = elementsFilter.filter(
          (element) => element.level === level
        );
      }

      if (tag) {
        elementsFilter = elementsFilter.filter((element) =>
        element.tags.find((elementTag) => elementTag === tag)
        );
      }

      setElementsData(elementsFilter);
    } else {
      setElementsData(elements);
    }
  };

  return (
    <div>
      <div className="challenges container mx-auto my-4 h-auto">
        <div className={`grid grid-cols-12 gap-4`}>
          {/* SIDEBAR */}
          <div className="col-span-12 sm:col-span-3 p-4 mx-2 bg-white">
            <div className="img-logo mt-4 text-center">
              <Link to={`/${data.id}`}>
                <img className="h-24" src={data.logo} alt={data.name} />
              </Link>
              <p className="text-gray-700 font-bold">{data.name}</p>
            </div>
            <select
              className="form-select mt-2"
              onBlur={(e) => setLevel(e.target.value)}
              defaultValue={level}
            >
              <option value="">Niveles</option>
              {levels.map((element, index) => (
                <option key={index}>{element}</option>
              ))}
            </select>
            <div className="my-2">
              <select
                className="form-select"
                onBlur={(e) => setTag(e.target.value)}
                defaultValue={tag}
              >
                <option value="">Etiquetas</option>
                {tags.map((element, index) => (
                  <option key={index}>{element}</option>
                ))}
              </select>
            </div>
            <div className="text-center">
              <button
                className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none"
                type="button"
                onClick={filter}
              >
                Buscar
              </button>
              <button
                className="text-indigo-500 px-4 py-2 m-2 transition duration-500 ease select-none hover:text-orange-600 focus:outline-none"
                type="button"
                onClick={cleaned}
              >
                Limpiar Filtro
              </button>
            </div>
          </div>
          <main className="col-span-12 sm:col-span-9 p-4 mx-2 bg-white">
            {elementsData && elementsData.length ? (
              <CardGrid elements={elementsData} />
            ) : (
              <div className="img-logo mt-4 text-center">
                <img src={notFound} alt="not-found" />
                <p className="text-xl font-bold mb-2">
                  Búsqueda no encontrada. Vuelva a intentarlo!
                </p>
              </div>
            )}
          </main>
        </div>
      </div>
    </div>
  );
};

export default Challenges;
