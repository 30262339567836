// Autor
export const AUTOR_DEFAULT = '@blucoding';

// Menu options
export const OPTION_CHALLENGES = 'challenges';
export const OPTION_DOCS = 'docs';
export const OPTION_QUIZZES = 'quizzes';

// Levels
export const LEVEL_VERY_EASY = 'Muy Fácil';
export const LEVEL_EASY = 'Fácil';
export const LEVEL_MEDIUM = 'Intermedio';
export const LEVEL_HARD = 'Difícil';
export const LEVEL_VERY_HARD = 'Muy Difícil';
export const LEVEL_EXPERT = 'Experto';

export const LEVELS = [
  LEVEL_VERY_EASY,
  LEVEL_EASY,
  LEVEL_MEDIUM,
  LEVEL_HARD,
  LEVEL_VERY_HARD,
  LEVEL_EXPERT,
];

// Tags
export const TAG_ARRAYS = 'arrays';
export const TAG_CLASSES = 'clases';
export const TAG_CLOSURES = 'closures';
export const TAG_CONDITIONS = 'condicionales';
export const TAG_DATES = 'fechas';
export const TAG_HIGHER_ORDER_FUNCTIONS = 'funciones de orden superior';
export const TAG_LOOPS = 'loops';
export const TAG_MATH = 'math';
export const TAG_NUMBERS = 'numbers';
export const TAG_OBJECTS = 'objetos';
export const TAG_RECURSION = 'recursividad';
export const TAG_REGEX = 'regex';
export const TAG_SCOPE = 'scope';
export const TAG_STRINGS = 'strings';
export const TAG_VALIDATIONS = 'validaciones';

// Contents
export const JAVASCRIPT = 'javascript';
export const KUBERNETS = 'kubernets';
