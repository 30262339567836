import React from 'react';

// Icons
import twitter from '@assets/icons/twitter.svg';
import youtube from '@assets/icons/youtube.svg';
import facebook from '@assets/icons/facebook.svg';
import instagram from '@assets/icons/instagram.svg';

const Footer = () => {
  return (
    <footer className='w-full bg-gray-700 text-white'>
      <div className='container mx-auto px-4 py-8 text-center'>
        <div className='lg:w-1/5 lg:mr-auto mb-10 lg:mb-0'>
          <div className='mb-2 text-white'>Redes Sociales</div>
          <div className='flex space-x-4 items-center justify-center'>
            <a href='https://www.google.com/' target='_blank' rel='noopener noreferrer' className='h-8 w-8'>
              <img src={youtube} alt="img-youtube"/>
            </a>
            <a href='https://www.google.com/' target='_blank' rel='noopener noreferrer' className='h-8 w-8'>
              <img src={facebook} alt="img-facebook"/>
            </a>
            <a href='https://www.google.com/' target='_blank' rel='noopener noreferrer' className='h-8 w-8'>
              <img src={instagram} alt="img-instagram"/>
            </a>
            <a href='https://www.google.com/' target='_blank' rel='noopener noreferrer' className='h-8 w-8'>
              <img src={twitter} alt="img-twitter"/>
            </a>
          </div>
        </div>

        <hr className='my-4' />
        <div className='text-center'>Blucoding © {new Date().getFullYear()} | Todos los derechos reservados</div>
      </div>
    </footer>
  );
};

export default Footer;
