import React from 'react';

// Styles
import './default-layout.scss';

// Components
import Header from '@components/header';
import Footer from '@components/footer';

const DefaultLayout = ({ children }) => {
  return (
    <>
      <Header siteTitle={`Title`} />
      <div className="mx-auto default-layout">
        <br />
        <main>{children}</main>
      </div>
      <Footer />
    </>
  );
};

export default DefaultLayout;
