import { JAVASCRIPT, CHALLENGES, TAGS } from '@services/data/javascript-data';
import * as Constants from '@constants/content.constant';
import Challenges from '@components/challenges';
import * as React from 'react';
export default {
  JAVASCRIPT,
  CHALLENGES,
  TAGS,
  Constants,
  Challenges,
  React
};