// Data
import { JAVASCRIPT } from './data/javascript-data';
import { KUBERNETS } from './data/kubernets.data';

const DOCS = [JAVASCRIPT, KUBERNETS];

export const getDocs = () => {
  return DOCS;
};

export const getDocById = (id) => {
  return DOCS.find((element) => element.id === id);
};
