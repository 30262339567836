import CardGrid from '@components/cardGrid';
import pixabayIcon from '@assets/logos/pixabay.svg';
import pexelsIcon from '@assets/logos/pexels.svg';
import freepikIcon from '@assets/logos/freepik.svg';
import unsplashIcon from '@assets/logos/unsplash.svg';
import picjumboIcon from '@assets/logos/picjumbo.svg';
import splitshireIcon from '@assets/logos/splitshire.svg';
import isorepublicIcon from '@assets/logos/isorepublic.svg';
import * as React from 'react';
export default {
  CardGrid,
  pixabayIcon,
  pexelsIcon,
  freepikIcon,
  unsplashIcon,
  picjumboIcon,
  splitshireIcon,
  isorepublicIcon,
  React
};