import React, { useState } from 'react';
import { Link } from 'gatsby';
import logo from '@assets/logos/blucoding.svg'

const Header = ({ siteTitle }) => {
  const [navbarIsOpen] = useState(false);

  return (
    <header>
      <nav className="navbar z-50 bg-primary text-white">
        <div className="navbar-brand">
          <Link to="/">
            <span className="font-semibold text-xl tracking-tight content-start mx-4">
              {/* {'Blucoding'} */}
              <img src={logo} style={{height:'75px'}} alt="img-logo"/>
            </span>
          </Link>
        </div>
        {/* <div className='block lg:hidden'>
          <button type='button' className='navbar-toggler' onClick={() => setNavbarOpen(!navbarOpen)}>
            <svg className='fill-current h-3 w-3' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
              <title>Menu</title>
              <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
            </svg>
          </button>
        </div> */}
        <div
          className={
            'w-full block flex-grow lg:flex lg:items-center lg:w-auto ' +
            (!navbarIsOpen && 'hidden')
          }
        ></div>
      </nav>
    </header>
  );
};

export default Header;
