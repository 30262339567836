import LiveCode from '@components/liveCode';
import Tabs from '@components/tabs';
import Resources from "../../../../src/content/javascript/challenges/calcular-promedio/resources.md";
import Solutions from "../../../../src/content/javascript/challenges/calcular-promedio/solutions.md";
import * as React from 'react';
export default {
  LiveCode,
  Tabs,
  Resources,
  Solutions,
  React
};