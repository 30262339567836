import CardGrid from '@components/cardGrid';
import udemyIcon from '@assets/logos/udemy.svg';
import platziIcon from '@assets/logos/platzi.svg';
import edteamIcon from '@assets/logos/edteam.svg';
import pluralsightIcon from '@assets/logos/pluralsight6.svg';
import linkedinIcon from '@assets/logos/linkedin.svg';
import courseraIcon from '@assets/logos/coursera.svg';
import * as React from 'react';
export default {
  CardGrid,
  udemyIcon,
  platziIcon,
  edteamIcon,
  pluralsightIcon,
  linkedinIcon,
  courseraIcon,
  React
};