import CardGrid from '@components/cardGrid';
import iconfinderIcon from '@assets/logos/iconfinder.svg';
import flaticonIcon from '@assets/logos/flaticon.svg';
import iconmonstrIcon from '@assets/logos/iconmonstr.svg';
import icomoonIcon from '@assets/logos/icomoon.svg';
import materialdesigniconsIcon from '@assets/logos/materialdesignicons.svg';
import lordiconIcon from '@assets/logos/lordicon.svg';
import joypixelsIcon from '@assets/logos/joypixels.svg';
import worldvectorlogoIcon from '@assets/logos/worldvectorlogo.svg';
import * as React from 'react';
export default {
  CardGrid,
  iconfinderIcon,
  flaticonIcon,
  iconmonstrIcon,
  icomoonIcon,
  materialdesigniconsIcon,
  lordiconIcon,
  joypixelsIcon,
  worldvectorlogoIcon,
  React
};