import * as Constants from '../../constants/content.constant';
import { getPagesById } from '@utils/pages-util';

// Icon
import icon from '@assets/logos/javascript.svg';

const ID = 'javascript';

const getMenu = (allPages, base) => {
  const pages = getPagesById(allPages, ID, base);
  return {
    'Introducción': {
      pages: [
        pages[''],
      ],
      isOpen: true,
    },
    'Fundamentos': {
      pages: [
        pages['variables'],
        pages['tipos-de-datos'],
        pages['scope'],
        pages['clouser'],
        pages['hoisting'],
        pages['math'],
        pages['loops'],
        pages['objetos'],
      ],
      isOpen: true,
    },
    'Arrays': {
      pages: [
        pages['arrays'],
        pages['arrays-acceder-a-sus-elementos'],
        pages['arrays-metodos'],
      ],
      isOpen: true,
    },
    'Estándares': {
      pages: [
        pages['estandares'],
      ],
      isOpen: true,
    },
  };
}

export const JAVASCRIPT = {
  id: ID,
  path: '/javascript',
  title: 'JavaScript',
  logo: icon,
  options: [
    Constants.OPTION_DOCS,
    // Constants.OPTION_CHALLENGES,
    // Constants.OPTION_QUIZZES,
  ],
  getMenu,
};



export const CHALLENGES = [
  {
    path: 'hola-mundo',
    title: 'Hola Mundo',
    description: 'Nuestro primer Hola Mundo! en Javascript',
    level: Constants.LEVEL_VERY_EASY,
    autor: Constants.AUTOR_DEFAULT,
    tags: [
      Constants.TAG_STRINGS,
    ],
  },
  {
    path: 'calcular-area-triangulo',
    title: 'Calcular Área Triangulo',
    description: 'Cree una función que calcule el área de un triángulo.',
    level: Constants.LEVEL_VERY_EASY,
    autor: Constants.AUTOR_DEFAULT,
    tags: [
      Constants.TAG_NUMBERS,
    ],
  },
  {
    path: 'calcular-promedio',
    title: 'Calcular Promedio',
    description: 'Cree una función que retorne el promedio de 3 notas.',
    level: Constants.LEVEL_VERY_EASY,
    autor: Constants.AUTOR_DEFAULT,
    tags: [
      Constants.TAG_NUMBERS,
    ],
  },
  {
    path: 'calcular-promedio',
    title: 'Test 1',
    description: 'bla bla bla.',
    level: Constants.LEVEL_HARD,
    autor: Constants.AUTOR_DEFAULT,
    tags: [
      Constants.TAG_STRINGS,
      Constants.TAG_MATH,
      Constants.TAG_LOOPS,
    ],
  },
  {
    path: 'calcular-promedio',
    title: 'Test 2',
    description: 'bla bla bla.',
    level: Constants.LEVEL_EXPERT,
    autor: Constants.AUTOR_DEFAULT,
    tags: [
      Constants.TAG_STRINGS,
      Constants.TAG_MATH,
      Constants.TAG_LOOPS,
    ],
  },
  {
    path: 'calcular-promedio',
    title: 'Test 3',
    description: 'bla bla bla.',
    level: Constants.LEVEL_MEDIUM,
    autor: Constants.AUTOR_DEFAULT,
    tags: [
      Constants.TAG_NUMBERS,
      Constants.TAG_MATH,
      Constants.TAG_LOOPS,
    ],
  },
];

export const TAGS = [
  Constants.TAG_ARRAYS,
  Constants.TAG_CONDITIONS,
  Constants.TAG_CLASSES,
  Constants.TAG_CLOSURES,
  Constants.TAG_DATES,
  Constants.TAG_HIGHER_ORDER_FUNCTIONS,
  Constants.TAG_LOOPS,
  Constants.TAG_MATH,
  Constants.TAG_NUMBERS,
  Constants.TAG_OBJECTS,
  Constants.TAG_RECURSION,
  Constants.TAG_REGEX,
  Constants.TAG_SCOPE,
  Constants.TAG_STRINGS,
  Constants.TAG_VALIDATIONS,
];
