import CardGrid from '@components/cardGrid';
import defaultIcon from '@assets/icons/video-game.svg';
import cssbattleIcon from '@assets/logos/cssbattle.svg';
import flexboxfroggyIcon from '@assets/logos/flexboxfroggy.svg';
import gridgardenIcon from '@assets/logos/gridgarden.svg';
import * as React from 'react';
export default {
  CardGrid,
  defaultIcon,
  cssbattleIcon,
  flexboxfroggyIcon,
  gridgardenIcon,
  React
};